<p-table
  #draegerTable
  styleClass="p-datatable-striped p-datatable-gridlines"
  class="draeger-table"
  [columns]="visibleColumns"
  [value]="dataTable"
  [scrollable]="true"
  scrollHeight="flex"
  [lazy]="lazy"
  [loading]="isLoading"
  [paginator]="paginator"
  [rows]="rows"
  [first]="first"
  [filterDelay]="filterDelay"
  [totalRecords]="totalRecords"
  [rowsPerPageOptions]="rowsPerPageOptions"
  (onSort)="changeSortIcon($event)"
  (clickOutside)="removeSelectedRow()"
  (onPage)="onPageOrRowChange($event)"
  (onLazyLoad)="onLazyLoadingData($event)"
  [showCurrentPageReport]="true"
  [currentPageReportTemplate]="
    'Showing ' +
    first +
    ' to ' +
    numberOfDisplayedEntities +
    ' of ' +
    totalRecords +
    ' total entities'
  "
  [sortField]="defaultSortField"
  [sortOrder]="sortColumn?.order"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        *ngFor="let col of columns"
        scope="col"
        [ngStyle]="{ width: col.width, flex: 'auto' }"
        [ngClass]="{
          'selected-sort-column': sortColumn?.field === col.field,
          'deselected-sort-column': sortColumn?.field !== col.field
        }"
      >
        <span class="sort-area" [pSortableColumn]="col.field">
          {{ col.header | translate }}
          <draeger-sort-icon
            *ngIf="!col.notSorting"
            [columnName]="col.field"
            [columnSorted]="sortColumn"
          ></draeger-sort-icon>
        </span>
      </th>
    </tr>

    <tr *ngIf="displayFilters">
      <th
        *ngFor="let col of columns"
        [ngStyle]="{ width: col.width, flex: 'auto' }"
        scope="col"
        [ngClass]="{
          'selected-sort-column': sortColumn?.field === col.field,
          'deselected-sort-column': sortColumn?.field !== col.field
        }"
      >
        <div
          scope="col"
          *ngFor="let customFilter of customFilterLayout"
          [ngClass]="{ 'w-100': col.field === customFilter }"
        >
          <p-columnFilter
            *ngIf="
              col.field === customFilter && col.filterType === filterType.TEXT
            "
            field="col.field"
            matchMode="contains"
            [showMenu]="false"
            [showClearButton]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                type="text"
                class="p-inputtext p-component p-element"
                pInputText
                [delay]="600"
                [ngModel]="tableStoredFilters && tableStoredFilters[col.field]"
                (keyup.enter)="
                  onTextFilterChange($event.target.value, col.field)
                "
              />
            </ng-template>
          </p-columnFilter>
          <p-columnFilter
            *ngIf="
              col.field === customFilter && col.filterType === filterType.RANGE
            "
            field="col.field"
            matchMode="contains"
            [showMenu]="false"
            [showClearButton]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                draegerInputDebounce
                type="number"
                onkeydown="return event.keyCode !== 69"
                class="p-inputtext p-component p-element w-50"
                pInputText
                step="any"
                [min]="0"
                [delay]="600"
                placeholder="Min"
                [ngModel]="
                  tableStoredFilters && tableStoredFilters['test_result_min']
                "
                (keyup.enter)="
                  onTextFilterChange($event.target.value, 'test_result_min')
                "
              />
              <input
                draegerInputDebounce
                type="number"
                onkeydown="return event.keyCode !== 69"
                class="p-inputtext p-component p-element w-50"
                pInputText
                step="any"
                [min]="0"
                [delay]="600"
                placeholder="Max"
                [ngModel]="
                  tableStoredFilters && tableStoredFilters['test_result_max']
                "
                (keyup.enter)="
                  onTextFilterChange($event.target.value, 'test_result_max')
                "
              />
            </ng-template>
          </p-columnFilter>

          <p-columnFilter
            *ngIf="
              col.field === customFilter && col.filterType === filterType.NUMBER
            "
            field="col.field"
            matchMode="contains"
            [showMenu]="false"
            [showClearButton]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <input
                draegerInputDebounce
                type="number"
                onkeydown="return event.keyCode !== 69"
                class="p-inputtext p-component p-element"
                pInputText
                step="any"
                [min]="0"
                [delay]="600"
                [ngModel]="tableStoredFilters && tableStoredFilters[col.field]"
                (keyup.enter)="
                  onTextFilterChange($event.target.value, col.field)
                "
              />
            </ng-template>
          </p-columnFilter>

          <div
            *ngIf="
              col.field === customFilter && col.filterType === filterType.DATE
            "
          >
            <draeger-datepicker
              [selectionMode]="datePickerSelectionMode"
              [formatDate]="datePickerFormat"
              [readOnly]="readOnlyDatePicker"
              [appendTo]="'body'"
              [showTime]="datePickerShowTime"
              [showSeconds]="datePickerShowSeconds"
              [defaultRange]="defaultRange"
              (dateSelect)="onDateFilterSelect($event, col.field)"
              (dateChange)="onDateFilterChange(col.field)"
            >
            </draeger-datepicker>
          </div>

          <span>
            <p-columnFilter
              *ngIf="
                col.field === customFilter &&
                col.filterType === filterType.MULTI_SELECT
              "
              field="col.field"
              matchMode="in"
              [showMenu]="false"
              [showClearButton]="false"
            >
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <span *ngFor="let items of multiSelectOptions; let i = index">
                  <span *ngFor="let item of items | keyvalue">
                    <span *ngIf="item.key === col.field">
                      <p-multiSelect
                        ngDefaultControl
                        class="report-center-table-ms"
                        [ngModel]="multiSelectActiveOption[i]"
                        [options]="multiSelectOptions[i][item.key]"
                        (onChange)="
                          onMultiSelectChange($event.value, col.field)
                        "
                        optionLabel="value"
                        optionValue="id"
                        [placeholder]="'-'"
                        [filter]="true"
                        [appendTo]="draegerTable"
                        [resetFilterOnHide]="true"
                      >
                        <ng-template let-option pTemplate="item">
                          <div class="p-multiselect-representative-option">
                            <span class="p-ml-1">{{
                              option.value | translate
                            }}</span>
                          </div>
                        </ng-template>
                        <ng-template let-value pTemplate="selectedItems">
                          <span *ngIf="!value?.length">-</span>
                          <div
                            *ngIf="value"
                            class="ui-multiselected-item-token ui-corner-all ellipsis-text"
                          >
                            <span *ngFor="let val of value; let j = index">
                              <span
                                *ngFor="
                                  let item of multiSelectOptions[i][item.key]
                                "
                              >
                                <span *ngIf="item.id === val.id">
                                  {{ item.value | translate }}
                                </span>
                              </span>
                              {{ j === value.length - 1 ? "" : ",&nbsp;" }}
                            </span>
                          </div>
                        </ng-template>
                      </p-multiSelect>
                    </span>
                  </span>
                </span>
              </ng-template>
            </p-columnFilter>
          </span>

          <p-columnFilter
            *ngIf="
              col.field === customFilter &&
              col.filterType === filterType.DROPDOWN
            "
            field="col.field"
            matchMode="equals"
            [showMenu]="false"
            [showClearButton]="false"
          >
            <ng-template
              pTemplate="filter"
              let-value
              let-filter="filterCallback"
            >
              <p-dropdown
                [ngModel]="dropdownActiveOption"
                ngDefaultControl
                [options]="col.dropdownOptions"
                (onChange)="onDropdownChange($event, col.field)"
                [showClear]="true"
                [placeholder]="'-'"
                [filter]="true"
                filterBy="label"
                [appendTo]="draegerTable"
                [resetFilterOnHide]="true"
              >
                <ng-template let-option pTemplate="item">
                  <span>{{ option.label?.toLowerCase() | translate }}</span>
                </ng-template>
                <ng-template let-option pTemplate="selectedItem">
                  <span>{{ option.label?.toLowerCase() | translate }}</span>
                </ng-template>
              </p-dropdown>
            </ng-template>
          </p-columnFilter>

          <div
            *ngIf="
              col.field === customFilter && col.filterType === filterType.MAPS
            "
          >
            <draeger-maps-coordinates
              (handleLocation)="onLocationChange($event, col.field)"
              [selectedCoordinates]="selectedCoordinates"
            >
            </draeger-maps-coordinates>
          </div>
        </div>
      </th>
    </tr>

    <div
      class="table-header-right-wrapper"
      [ngClass]="{
        'selected-sort-column': sortColumn?.field === lastTableElement,
        'deselected-sort-column': sortColumn?.field !== lastTableElement
      }"
    ></div>
  </ng-template>
  <ng-template pTemplate="body" let-data let-columns="columns">
    <tr
      (click)="onSelect(data)"
      [ngClass]="{
        'selected-row': selectedData?.id === data.id
      }"
      [pSelectableRow]="data"
    >
      <td
        *ngFor="let col of columns"
        [ngStyle]="{
          width: col.width,
          flex: 'auto',
          'justify-content': col.textAlign,
          position: col.position
        }"
      >
        <span
          style="display: flex; align-items: center; gap: 5px"
          *ngIf="
            !customColumnsLayout?.includes(col.field) && col.field !== 'access'
          "
        >
          @if(col.field?.includes('result_meas_1') && data['status_meas_1'] &&
          data['result_meas_1'] !=='-'&& data['result_meas_1']) {
          <div
            class="test_result_dot"
            [ngStyle]="{ background: data['status_meas_1'] }"
          ></div>
          } @if(col.field?.includes('result_meas_2') && data['status_meas_2'] &&
          data['result_meas_2'] !=='-'&& data['result_meas_2']) {
          <div
            class="test_result_dot"
            [ngStyle]="{ background: data['status_meas_2'] }"
          ></div>
          } @if(col.field?.includes('result_meas_3') && data['status_meas_3'] &&
          data['result_meas_3'] !=='-' && data['result_meas_3']) {
          <div
            class="test_result_dot"
            [ngStyle]="{ background: data['status_meas_3'] }"
          ></div>
          }
          {{ data[col.field] ? (data[col.field] | translate) : "-" }}
        </span>

        <span
          *ngIf="col.field === 'access'"
          pTooltip="Workflows: {{ tooltipText(data.workflows) }}

      PDFs: {{ tooltipText(data.pdfs) }}

      Printouts: {{ tooltipText(data.printouts) }}
      
      BMPs: {{ tooltipText(data.bmps) }}
      "
          tooltipPosition="bottom"
          [tooltipDisabled]="!disableTooltip"
          tooltipStyleClass="companies-tooltip"
          >{{ data[col.field] ? (data[col.field] | translate) : "-" }}</span
        >

        <span *ngFor="let customColumn of customColumnsLayout; index as i">
          <span *ngIf="col.field === customColumn && col.field !== 'access'">
            <ng-template
              ngFor
              let-template
              [ngForOf]="customColumnsRef"
              let-j="index"
            >
              <div *ngIf="i === j">
                <ng-container
                  *ngTemplateOutlet="
                    template;
                    context: { $implicit: data[col.field] }
                  "
                ></ng-container>
              </div>
            </ng-template>
          </span>
        </span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td>{{ "no_entries_found" | translate }}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorright" let-state>
    <span> {{ "of" | translate }}{{ " " + state.pageCount }}</span>
  </ng-template>
</p-table>
